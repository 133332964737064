import React, { useEffect, useState } from 'react';
import { Box, Title, SubTitle, Text, Button, Space } from '../theme/theme';
import MetaTags from 'react-meta-tags';
import { useSelector } from 'react-redux';
import Spinner from '../components/spinner';
import { Container, Grid } from '@mui/material';
import BlockContent from '@sanity/block-content-to-react'

import settings from "../settings.json"
import { Link } from 'react-router-dom';
import { AvatarList } from '../components/avatar';

// TODO: implement

const Home = ()=>{
    const [data, setData] = useState(undefined);
    const client = useSelector(state=>state.data.client);
    const members = useSelector(state=>state.data.members);

    useEffect(()=>{
        if (!data && client){
            client.getHome()
            .then(newdata=>setData(newdata));
        }
    })

    const Meta = <MetaTags>
                    <title>{"CHAMELEON ESRs / Home"}</title>
                    <meta name="description" content={"CHAMELEON is a Marie Curie Innovative Training Network for European Joint Doctorates. ESR community platform"} />
                    <meta property="og:title" content={"CHAMELEON ESRs / Home"} />
                </MetaTags>
    
    if (!data || !members){
        return <>
                {Meta}
                <Spinner />
            </>
    } else {
        return <>
                {Meta}
                <Container>
                        <Grid container spacing={2}>
                            <Grid item xs={false} lg={10} md={11} sm={12}>
                                <Box>
                                    <Grid item md={0}>
                                        <Title>
                                            {data.title}
                                        </Title>
                                        <SubTitle>
                                            {data.subtitle}
                                        </SubTitle>
                                        <br/><br/><br/>
                                        <Text>
                                            <BlockContent blocks={data.intro} target="_blank" />
                                        </Text>
                                        <br/><br/>
                                        
                                        <AvatarList data={members.map(({firstname, familyname, avatar})=>({title: `${firstname} ${familyname}`, avatar, url: `members/${firstname.toLowerCase()}`}))} />

                                        <br/><br/>

                                        <Button to={"/winterschool2"}>
                                            CHAMELEON WINTERSCHOOL II
                                        </Button> 
                                        <Space />

                                        {/* <Button to="/members/oriel/redshift">
                                            PROJECT REDSHIFT
                                        </Button>  */}


                                        
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>   

    </>
    }
}



export default Home;
