import { Container, Grid } from '@mui/material';
import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, ResponsiveImage, Title, Text, SubTitle } from '../theme/theme';
import MetaTags from 'react-meta-tags';
import { useParams } from "react-router-dom";
import Redshift from "../components/redshift"
import Education from '../components/education';
import { AvatarList } from '../components/avatar';
import Spinner from '../components/spinner';
import { Member } from '../components/member';
import { Avatar } from '../components/avatar';
import BlockContent from '@sanity/block-content-to-react'

// TODO: implement

const Projects = (props) => {
    const [project, setProject] = useState(null);
    const params = useParams();
    const projects = useSelector(state=>state.data.projects);

    useEffect(()=>{
        if (params){
            if (!project && params.project && projects){
                projects.forEach(m => {
                    if (m.slug == params.project){
                        setProject(m);
                        console.log(m)
                    } 
                });
            }
            // if (!project && params.project){
            //     setProject(params.project)
            // }   
        }
    })

    
    if (!project){
        return <Spinner/>
    } else {
        return   <>
        <MetaTags>
            <title>{"CHAMELEON ESRs / Projects"}</title>
            <meta name="description" content={"CHAMELEON is a Marie Curie Innovative Training Network for European Joint Doctorates. ESR community platform"} />
            <meta property="og:title" content={"CHAMELEON ESRs / Project / " + project.title} />
            </MetaTags>
            <Container style={{margin: "10px"}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4} lg={3} >
                    <Avatar avatar={project.heading} />
                </Grid>
                <Grid xs={false} md={1} lg={1}>
                    &nbsp;
                </Grid>
                <Grid xs={12} md={8} lg={7}>
                    <Title>
                        {project.title}
                    </Title>

                    <Text>
                        <BlockContent blocks={project.description} />
                    </Text>
                    <br/><br/>
                    {project.graphics.map(({img})=><img src={img} style={{width: "100%"}} />)}
                    <br/><br/><br/>
                    <SubTitle>Main Contributors</SubTitle>
                    <AvatarList data={project.contributors.map(({title, avatar})=>({title: `${title}`, avatar, url: `/members/${"pieter"}`}))} />

                    {/* {buttons.map(b=> <Button key={b.title} to={b.url} external> {b.title} </Button>)} */}
                    <br/><br/><br/><br/>
                </Grid>
            </Grid>
        </Container>
        </>
    }
}

export default Projects;
