import React   from "react";
import { useSelector } from 'react-redux';
import { Container, Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from "react-router-dom";
import { memberQuery } from "../client/queries";
import MetaTags from 'react-meta-tags';
import theme, { Box, Title, SubTitle, Text, Button, Space } from '../theme/theme';
import { Avatar } from "./avatar";
import BlockContent from '@sanity/block-content-to-react'

export const Member = ({firstname, familyname, avatar, description, buttons, projects, slug})=>{
    const client = useSelector(state=>state.data.client);
    const storedprojects = useSelector(state=>state.data.projects);

    const Meta = <MetaTags>
        <title>{"CHAMELEON ESRs / " + firstname}</title>
        <meta name="description" content={"CHAMELEON is a Marie Curie Innovative Training Network for European Joint Doctorates. ESR community platform"} />
        <meta property="og:title" content={"CHAMELEON ESRs / " + firstname} />
    </MetaTags>

    

    return <div> 
        {Meta}
        <br/><br/><br/>
        <Container style={{margin: "10px"}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4} lg={3} >
                    <Avatar avatar={avatar} />
                </Grid>
                <Grid xs={false} md={1} lg={1}>
                    &nbsp;
                </Grid>
                <Grid xs={12} md={8} lg={7}>
                    <Title>
                        {firstname} {familyname} 
                    </Title>

                    {projects? <div>
                        <br/><br/>
                        <SubTitle>
                            Selected Projects
                        </SubTitle>
                        {projects.map(b=> {
                            if (client && storedprojects){
                                
                                let result = null;
                                
                                storedprojects.forEach((p)=>{
                                    if (p._id == b._ref){
                                        result = p;
                                    }
                                })

                                b = result;
                                
                                if (result){
                                    return <Button key={b._id} to={"/projects/"+b.slug} external> {b.title} </Button>
                                } else {
                                    return null;
                                }
                            } else {
                                return null;
                            }
                        
                        })}
                        <br/><br/>
                        <SubTitle>
                            Main Research
                        </SubTitle>
                    
                    </div> : null}

                    <Text>
                        {projects?null:<div><br/><br/></div>}
                        <BlockContent blocks={description} />
                    </Text>
                    <br/><br/>
                    {buttons.map(b=> <Button key={b.title} to={b.url} external> {b.title} </Button>)}
                    <br/><br/><br/><br/>
                </Grid>
            </Grid>
        </Container>
    </div>

    
}


// export const Avatar = ({avatar, title, mobile, url})=>{
//     if (mobile){
//         return <span>
//                 <Link to={url}>
//                 <img src={avatar} style={{width: "70px", borderRadius: "50%", transform: "translate(0, 25px)"}}/>
//                 <Text>
//                 <i style={{margin: "20px"}}>
//                     {title}
//                 </i>
//             </Text>
//             <br/>
//             <br/>
//             </Link>
//         </span>
//     }

//     return  <Link to={url}>
//         <img src={avatar} style={{width: "100%", borderRadius: "50%"}}/>
//         <div style={{textAlign: "center", marginTop:"10px"}}>
//             <Text>
//                 <i>
//                     {title}
//                 </i>
//             </Text>
//         </div>
//     </Link> 
    
// }

