import {useEffect} from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { createBrowserHistory } from 'history';
import Home from './Home'
import NotFound from './404'
import Members from "./members"
import theme from "../theme/theme"
import { ThemeProvider} from '@mui/material/styles';
import sanityclient from '../client';
import { useSelector, useDispatch } from "react-redux";
import { ACTIONS as DATA_ACTIONS } from "../redux/reducers/DataReducer"
import Winterschool from "./Winterschool";
import Projects from "./projects";


// // import themer
// // import './App.css';
// /*

// TODO:
//   - bring in client 
// - make project page
// - make member page
// - make 

// */

const DataHelper = () => {
  const data = useSelector(state => state.data);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!data.client && dispatch){
      const cli = sanityclient(dispatch);
      dispatch({ type: DATA_ACTIONS.SET_CLIENT, client: cli});
    } 

    if (data.client && !data.members){
      data.client.getMembers()
      .then(members=>{
        dispatch({ type: DATA_ACTIONS.SET_MEMBERS, members});
      })
    }

    if (data.client && !data.projects){
      data.client.getProjects()
      .then(projects=>{
        dispatch({ type: DATA_ACTIONS.SET_PROJECTS, projects});
      })
    }

  })
  return (
    null
  )
}

function Chameleon() {
  const history = createBrowserHistory();
  return (
    <BrowserRouter history={history}>
        <DataHelper />
        <ThemeProvider theme={theme}>
         <Routes>
            <Route path='/' element={<Home/>} />
            <Route path='/members' element={<Members/>} />
            <Route path='/members/:name' element={<Members/>} />
            <Route path='/members/:name/:project' element={<Members/>} />
            <Route path='/projects/:project' element={<Projects/>} />
            <Route path='/winterschool2' element={<Winterschool/>} />
            <Route path="*" element={<NotFound />} />
         </Routes>
         </ThemeProvider>
    </BrowserRouter>
  );
}

export default Chameleon;


