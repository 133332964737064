
import {createTheme, Typography, responsiveFontSizes } from "@mui/material";
import {default as MButton} from "@mui/material/Button"
import { Link } from "react-router-dom";

const defaultTheme = {
    palette: {
      type: 'light',
      primary: {
        main: '#000000',
      },
      secondary: {
        main: '#ffff',
      },
    },
    typography: {
      fontFamily: 'Poppins',
      fontSize: 14,
      h1: {
        fontWeight: 600,
        fontSize: '6rem',
      },
      h2: {
        fontSize: '3.8rem',
        fontWeight: 100
      },
      h3: {
        fontWeight: 1000,
        fontSize: '2.7rem',
      },
    },
    components: {
        MuiButton: {
          variants: [
            {
              props: { variant: 'hard' },
              style: {
                textTransform: 'none',
                border: `2px solid black`,
                borderRadius: "0px"
              },
            },
          ],
        },
      },
  };

  

const theme =  responsiveFontSizes(createTheme(defaultTheme));


//   typography
export const Title = ({children})=>{
    return <Typography variant="h1">{children}</Typography>
}

export const SubTitle = ({children})=>{
    return <Typography variant="h2">{children}</Typography>
}

export const Text = ({children})=>{
  return <Typography component={'span'} variant="body1">{children}</Typography>
}

// button
export const Button = ({children, to, color, external})=>{
  color = color?color:defaultTheme.palette.primary;
  const B = <MButton  color="primary" variant="hard" style={{color:color, borderColor: color, textDecoration: "none", margin: "5px"}}>
  {children}
</MButton>

  if (to){
    if (external){
      return <a href={to} target="_blank">{B}</a>
    } else {
      return <Link to={to}>
        {B}
    </Link>
    }
  } else {
    return B;
  }
}

//   media 
export const ResponsiveImage = ({src})=>{
    return <img src={src} style={{width: "100%"}} />
}

export const Space = ()=><>&nbsp;</>

// contianers
export const Box = ({children})=>(
  <div style={{margin: "50px"}}>
    {children}
  </div>
)
  
export default theme;

