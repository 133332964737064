import React, { useEffect, useRef, useState } from 'react';
import theme, { Box, Title, SubTitle, Text, Button, Space } from '../theme/theme';
import MetaTags from 'react-meta-tags';
import { useSelector } from 'react-redux';
import Spinner from '../components/spinner';
import { Container, Grid } from '@mui/material';
import BlockContent from '@sanity/block-content-to-react'
import useMediaQuery from '@mui/material/useMediaQuery';


import settings from "../settings.json"
import { Link } from 'react-router-dom';
import { AvatarList } from '../components/avatar';
import { SegmentSharp } from '@mui/icons-material';

// TODO: implement

const Winterschool = ()=>{
    const [data, setData] = useState(undefined);
    const client = useSelector(state=>state.data.client);
    const [project, setProject] = useState(undefined);
    const [hover, setHover] = useState(false);
    const [blur, setBlur] = useState(0);
    const [initial, setInitial] = useState(undefined);
    const md = useMediaQuery(theme.breakpoints.up("md"));
    const lg = useMediaQuery(theme.breakpoints.up("lg"));
    const mobile = useMediaQuery(theme.breakpoints.down("md"));
    const onlyMD = useMediaQuery(theme.breakpoints.only("md"));
    const onlyLG = useMediaQuery(theme.breakpoints.only("lg"));
    const projectRef = useRef(null)


    useEffect(()=>{
        if (initial == undefined){
            setInitial(Math.floor(Math.random()*4))
        }
        

        if (!data && client){
            client.getWinterSchool()
            .then((newdata)=>{
                setData(newdata);
            });
        }

        const intervalID = setInterval(()=>{
            setBlur(Math.min(Math.max(0, blur + ((Math.random()*2-1))*50), 400));
            
        }, 100);

        return ()=>clearInterval(intervalID);
    })

    const Meta = <MetaTags>
                    <title>{"CHAMELEON ESRs / WINTERSCHOOL II"}</title>
                    <meta name="description" content={"The second CHAMELEON school was held in January of 2022 and focused on putting the research that the network is conducting into the context of the big science questions of our time"} />
                    <meta property="og:title" content={"CHAMELEON ESRs / SCHOOL2"} />
                    {/* <meta property="og:image" content="path/to/image.jpg" /> */}
                </MetaTags>
    
    
    if (!data ){
        return <>
                {Meta}
                <Spinner />
            </>
    } else {
        const renderIntro = (data, offset)=><Grid item sm={12} md={10} lg={6} style={{padding: mobile?null:"30px", marginTop: offset?"300px":null, marginBottom:"30px"}}>
            <SubTitle>
                {data.title}
            </SubTitle>
            <Text>
                <BlockContent blocks={data.description}/>
            </Text>
        </Grid>

        const header = 
        <div style={{overflow: "hidden", objectFit: "cover"}}>
            <img src={ project?project.heading:data.projects[initial].heading}  style={{padding: "20px"}} />
        </div>
        return <>
                {Meta}
                <div style={{backgroundImage: mobile?null:`url(${project?project.background:data.projects[initial].background})`}}>
                    <div style={{backdropFilter: mobile?null:`blur(${blur}px)`, padding: mobile?"0px":!(md || lg)?"16px":"50px"}}>
                        <div style={{background: "white"}}>
                            {mobile?<><br/></>:null}
                            <Grid container spacing={2}>
                                <Grid item  lg={12} md={12} sm={12}>
                                    {!(md || lg)?header:null}
                                        <Container>
                                            <Box>
                                                {(md || lg)?header:null} 
                                                <Grid container>
                                                    {renderIntro(data.intro1)}
                                                    {onlyMD? <>
                                                        <Grid item sm={0} lg={0} md={1}>&nbsp;</Grid>
                                                        <Grid item sm={0} lg={0} md={1}>&nbsp;</Grid>
                                                        <Grid item sm={0} lg={0} md={1}>&nbsp;</Grid>
                                                    </>:null}
                                                    {renderIntro(data.intro2, lg && !onlyMD)}
                                                </Grid>
                                                <br/><br/>
                                                <div style={{textAlign: "center", margin:"0px"}}>
                                                    <SubTitle>
                                                        PROJECTS
                                                    </SubTitle>
                                                    {mobile?<><br/><br/></>:null}
                                                </div>
                                                {data.projects.map(({title, _id, color, logo}, index)=>{
                                                    const mask = "url("+logo+") no-repeat center";
                                                    color = "#"+color;
                                                    const ucolor = (hover==_id)?color:(project && project._id==_id)?color:"black";
                                                    let width = "100%";
                                                    if (md){
                                                        width = "50%";
                                                    } 
                                                    if (lg){
                                                        width = "25%";
                                                    }

                                                    return <div key={_id} style={{width, textAlign: "center", display: "inline-block", float: "left"}}
                                                                onMouseEnter={()=>{ setHover(_id);}} onMouseLeave={()=>{setHover(false);}} onClick={()=>{
                                                                    setProject(data.projects[index])
                                                                    setTimeout(()=>{
                                                                        if(projectRef && projectRef.current){
                                                                            projectRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                                                                        }
                                                                    }, 200);
                                                                    }} >
                                                                <div style={{padding: mobile?null:"10px", margin: mobile? "0px":"10px"}}>
                                                                    <div style={{ justifyContent: "center", alignItems: "center", display: "flex"}}>
                                                                        <div style={{backgroundColor: ucolor, width: "100%", height: mobile?"70px":"200px", WebkitMask: mask, mask}}>
                                                                            &nbsp;
                                                                        </div>
                                                                    </div>
                                                                    <Text>
                                                                        {/* {mobile?<div style={{ justifyContent: "center", alignItems: "center", display: "flex", }}>
                                                                        <div style={{backgroundColor: ucolor, width: "100%", height: "50px", WebkitMask: mask, mask}}>
                                                                            &nbsp;
                                                                        </div>
                                                                    </div>:null} */}
                                                                        <span style={{fontSize: "140%", letterSpacing: "0.5em", color: ucolor}}>
                                                                            {title}
                                                                        </span>
                                                                    </Text>
                                                                </div>
                                                                <div>
                                                                </div>
                                                                <br/><br/><br/>
                                                    </div>                
                                                })}
                                            </Box>
                                         </Container>
                                            
                                        {project? 
                                            <div style={{}} >
                                                <Container>
                                                <div style={{textAlign: "center", width: "100%"}}>
                                                    <Text>
                                                        <span style={{fontSize: mobile?"1.8em":"3em", fontWeight: "bold", lineHeight: "110%"}} ref={projectRef}>
                                                            {project.longtitle}
                                                        </span>
                                                        <br/><br/>
                                                        <span style={{fontSize:mobile?"1.4em":"2em", fontWeight: "normal", lineHeight: "110%"}}>
                                                            {project.tagline}
                                                        </span>
                                                    </Text>
                                                </div>
                                                <div style={{width: "100%"}}>
                                                    <br/><br/><br/>
                                                    <AvatarList data={project.authors} />
                                                    <br/><br/>
                                                </div>

                                                </Container>
                                                {project.segments.map(({_key, title, description, supportingGraphics})=>{

                                                    return <div key={_key + title} style={{marginTop: "60px", margin: mobile?"20px":null}}>
                                                        <Grid container>
                                                            <Grid item sm={1}></Grid>
                                                            <Grid item sm={10} lg={5}>
                                                                <SubTitle>
                                                                    {title}
                                                                </SubTitle>
                                                                <Text>
                                                                {description?<BlockContent blocks={description} />:null} 
                                                                </Text>
                                                            </Grid>
                                                            
                                                            <Grid item sm={2} md={1} lg={1}>  </Grid>
                                                            {!lg?<Grid item sm={0} md={4} lg={false}>  </Grid>:null}
                                                            <Grid item sm={8} md={7} lg={4}>
                                                                { supportingGraphics?supportingGraphics.map(({_key, description, title, img})=><div key={_key + title} style={{marginTop: "50px"}}> 
                                                                    <img src={img} style={{width: "100%"}} />
                                                                    <Text>
                                                                        <br/>
                                                                        <b >{title}</b>
                                                                        <i>
                                                                        {description?<BlockContent blocks={description} />:null} 
                                                                        <br/>
                                                                        </i>
                                                                    </Text>
                                                                </div>):null}
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                })}
                                                
                                                <Container>
                                                    {mobile?<div><br/><br/><br/></div>:null}
                                                <Grid container spacing={12} justifyContent="center" alignItems="center">
                                                    <Grid item xs={12} md={10} xl={6} >
                                                        <Text>
                                                            {project.feedback.quotes.map(v=>{
                                                                return <span style={{fontSize: mobile?"1.8em":"2em", fontWeight: "bold", lineHeight: "110%", color: "#"+project.color}}>
                                                                    <i>
                                                                        {v}
                                                                    </i>
                                                                <br/><br/>
                                                            </span>
                                                            })}
                                                        </Text>
                                                    </Grid>
                                                    <Grid item xs={12} md={10} xl={6}>
                                                        <div style={{backgroundColor: "#"+project.color, padding: "20px", color: "white"}}>
                                                        <i>
                                                        <Text>
                                                        {project.feedback.description?<BlockContent blocks={project.feedback.description} />:null} 
                                                        </Text>
                                                        </i>
                                                        </div>
                                                    </Grid>
                                                    
                                                    
                                                    </Grid>
                                                    </Container>
                                                
                                                
                                                
                                               </div> 
                                            : null}
                                    </Grid>
                                </Grid>
                                
                                <div style={{padding: "20px"}}>
                                    <div style={{maxWidth: "800px", margin: "0 auto", padding: "20px", }}>
                                        <i style={{textAlign: "center"}}>
                                            <Text>
                                                <BlockContent blocks={data.footer.description}/>
                                            </Text>
                                        </i>
                                    </div>
                                </div>

                                <Grid container  justifyContent="center" alignItems="center" sx={{padding: "50px"}}>
                                    {data.logos.map(({_key, img})=>{
                                        return <Grid item key={_key} xs={3} sm={2} md={2} lg={1} style={{margin:"10px", padding: "10px"}}>
                                            <img src={img}  style={{width: "100%"}} />
                                        </Grid>
                                    })}
                                </Grid>

                            </div>
                        </div>
                    </div>  

    </>
    }
}



export default Winterschool;
