import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import Spinner from "./spinner";
import { Container, Grid, Box, Typography } from '@mui/material';
import {ResponsiveImage, SubTitle, Text, Title, Button } from '../theme/theme';
import BlockContent from '@sanity/block-content-to-react'
import Divider from '@mui/material/Divider';


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/Inbox';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import LinkIcon from '@mui/icons-material/Link';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import { Link } from "react-router-dom";

const Redshift = ()=>{
    const [data, setData] = useState(null);
    const client = useSelector(state=>state.data.client);

    useEffect(()=>{
        if (!data && client){
            client.getRedshift()
            .then(newdata=>setData(newdata));
        }
    })

    const renderResource = (resourcelist)=>{
        if (!resourcelist){
            return null;
        }
        
        const getIcon = (type)=>{
            const sx = {fontSize: "60px", margin:"25px", marginLeft: "10px"};
            switch(type){
                case "excel":
                return <DataUsageIcon sx={sx} color="secondary"/>
                break;
                case "powerpoint":
                return <CoPresentIcon sx={sx} color="secondary"/>
                break;
                case "word":
                    return <TextFormatIcon sx={sx} color="secondary"/>
                break;
                case "hyperlink":
                    return <LinkIcon sx={sx} color="secondary"/>
                break;
                case "pdf":
                    return <DocumentScannerIcon sx={sx} color="secondary"/>
                default:
                    return <InboxIcon sx={sx} color="secondary"/>
                break;
            }
        }

        return ( <div>

            {resourcelist.filter(({_type})=>(_type=="vimeo")).map(({url, description})=>(
                <>
                <iframe src={"https://player.vimeo.com/video/"+url+"?h=c0c7c63ea5&color=fefefe&title=0&byline=0&portrait=0"} width="100%" height="500px" align="left" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{marginBottom: "0px"}} />
                <Text>
                    <i>
                        {description}
                    </i>
                    <br/><br/>
                </Text>
                </>
            ))}

            <Grid item xs={1} md={2}>
                <br/><br/><br/>
                <Typography variant="h4">Downloads</Typography>
            </Grid>
            <br/>
            
            
                

            {resourcelist.filter(({_type})=>(_type!="vimeo")).map(({title, _key, _type, description, url, file})=>(
                <Grid item xs={12}>
                <Box  sx={{ 
                width: '100%', 
                bgcolor: "primary.dark",
                }}>
                <List color="secondary">
                
                <div key={title+_key}>
                     <ListItem disablePadding>
                         <a href={url?url:file} target="_blank">
                            <ListItemButton >
                                <ListItemIcon > 
                                        {getIcon(_type)} 
                                </ListItemIcon>
                                <ListItemText disableTypography primary={
                                <Typography type="h3" style={{ color: '#FFFFFF' }}>
                                    <b>{title}</b>
                                </Typography>} secondary={<Typography>
                                    {description}
                                    <br/><br/>
                                    <u> click to download {_type} </u>
                                </Typography>} sx={{color: "white"}}  />
                            </ListItemButton>
                            <ListItemButton >
                                
                                
                            </ListItemButton>
                         </a>
             </ListItem>
                </div>
                </List>
              </Box>
              <br/>
            </Grid>
            ))}

            
            <br/><br/><br/><br/>
            
        </div>
        )
    }

    if (!data){
        return <Spinner />
    } else {
        return  (<>
            {data.intro.img?<ResponsiveImage src={data.intro.img} />:null}
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                            <Grid item md={0}>
                                <br/><br/>
                                <div style={{fontWeight: "600", fontSize: "4rem"}}>
                                    {data.intro.title}
                                </div>
                            </Grid>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Text>
                            <BlockContent blocks={data.intro.description} />
                        </Text>
                        <br/><br/>
                    </Grid>
                                  
                    {data.segments.map(({title, description, resources})=>(
                        <Grid item key={title} sm={12} md={8}>
                            <Typography variant="h2">{title}</Typography>
                            <Text style={{margin: "0px", padding: "0px"}}>
                                <BlockContent blocks={description} />
                            </Text>
                            {renderResource(resources)}
                        </Grid>)
                        )}
                        
                        <Grid item xs={12} lg={8}>
                        <br/><br/><br/><br/>
                            <Grid item md={0}>
                                <SubTitle>
                                    {data.exit.title}
                                </SubTitle>
                                <Text>
                                    <BlockContent blocks={data.exit.description} />
                                </Text>
                                <img src={data.exit.img} style={{width: "100%", margin: "15px"}} />
                                <img src={data.exit.img2} style={{width: "100%", margin: "15px"}} />
                            </Grid>
                            <br/><br/>
                    </Grid>
                </Grid>
        </Container>   
        <div style={{width: "100%", padding: "10px", background: "black", color: "white"}}>
            <Grid container>
                <Grid item lg={1} sx={{ display: { xs: 'none', sm: 'block' } }}></Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Text >
                        <br/><br/><br />
                        <BlockContent blocks={data.footer.description} />
                        
                    </Text>
                </Grid>
                <Grid item lg={1} sx={{ display: { xs: 'none', sm: 'block' } }}></Grid>
                <Grid item xs={12} sm={4} md={6}>
                    <Grid container style={{paddingTop: "50px"}}>
                        
                {
                    data.footer.partners.map(({url, img, _key})=>(
                        <Grid item xs={6} sm={12} md={6} lg={4} key={_key}>
                            <div style={{margin: "15px"}}>
                                <a href={url} target="_blank">
                                <img src={img} style={{width: "100%", margin: "15px"}} />
                                </a>
                            </div>
                        </Grid>
                    ))
                }

                </Grid>
                </Grid>
            </Grid>
        </div>
        </>
        )
    }
}

export default Redshift;

