import React from "react";
import { Container, Grid } from '@mui/material';
import theme, { Text } from "../theme/theme";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from "react-router-dom";

export const AvatarList = ({data})=>{
    const mobile = useMediaQuery(theme.breakpoints.down("md"));

    if (mobile){
        return <div style={{paddingLeft: "10%"}}>
            {data.map(({_key, avatar, title, url})=><Avatar key={_key} avatar={avatar} title={title} mobile={mobile} url={url}/>)}
        </div>
    } else {
        return <Grid container style={{justifyContent: "center"}}>
                    {data.map(({_key, avatar, title, url})=><Grid key={_key} item sm={4} md={4} lg={3} sx={{margin: "25px"}}>  <Avatar avatar={avatar} title={title} url={url} /> </Grid>)}
            </Grid>
    }
}


export const Avatar = ({avatar, title, mobile, url})=>{
    url = url?url:"";

    if (mobile){
        return <span>
                <Link to={url}>
                <img src={avatar} style={{width: "70px", borderRadius: "50%", transform: "translate(0, 25px)"}}/>
                <Text>
                <i style={{margin: "20px"}}>
                    {title}
                </i>
            </Text>
            <br/>
            <br/>
            </Link>
        </span>
    }

    return  <Link to={url}>
        <img src={avatar} style={{width: "100%", borderRadius: "50%"}}/>
        <div style={{textAlign: "center", marginTop:"10px"}}>
            <Text>
                <i>
                    {title}
                </i>
            </Text>
        </div>
    </Link> 
    
}

